import type { NextConfig } from 'next'
import getConfig from 'next/config'

const config: NextConfig = getConfig()
const { serverRuntimeConfig = {}, publicRuntimeConfig = {} } = config

const mergedConfig: { [key: string]: any } = {
  ...serverRuntimeConfig,
  ...publicRuntimeConfig,
}

export default mergedConfig
