/** @type {import('next').NextConfig} */
const { format: formatDate, isDate } = require('date-fns')
const { en, es, fr, de } = require('date-fns/locale')
const locales = { en, 'es-ES': es, fr, de }

module.exports = {
  i18n: {
    locales: ['en', 'es-ES', 'fr', 'de'],
    defaultLocale: 'en',
  },
  serializeConfig: false,
  interpolation: {
    format: (value, format, lng) => {
      if (isDate(value)) {
        const locale = locales[lng]
        return formatDate(value, format, { locale })
      }

      return value
    },
  },
}
