import 'styles/globals.css'

import { appWithTranslation } from 'next-i18next'
import nextI18NextConfig from 'next-i18next.config.js'
import { useEffect } from 'react'
import Head from 'next/head'
import TagManager from 'react-gtm-module'
import config from 'config'

import type { AppProps } from 'next/app'
import ReactModal from 'react-modal'
ReactModal.setAppElement('#modal-app')

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    const { gtm } = config
    if (gtm.enabled && typeof window !== 'undefined') {
      const args = {
        gtmId: gtm.id,
      }

      TagManager.initialize(args)
    }
  }, [])

  return (
    <>
      <Head>
        <title key="title">TotalSystemCare</title>
        <meta name="author" content="TotalSystemCare" />
        <link rel="icon" href="/img/fav.svg" />
      </Head>
      <Component {...pageProps} />
    </>
  )
}

export default appWithTranslation(App, nextI18NextConfig)
